<template>
  <div class="auth-wrapper auth-v1 px-2">
    <b-modal
      id="modal-1"
      centered
      size="lg"
      :ok-disabled="true"
      no-close-on-backdrop
      hide-footer
      hide-header
    >
      <div v-if="payment_modal_tab==='iframe'">
        <b-alert
          v-if="iframe.src===null"
          variant="success"
          show
        >
          <h4 class="alert-heading">
            {{ $t('creditcardpay.you_are_directed_to_the_bank_verification_page') }}
          </h4>
          <h4 class="alert-body">
            {{ $t('creditcardpay.wait_plase') }}
          </h4>
        </b-alert>
        <iframe
          v-if="iframe.src"
          v-show="iframe.loaded"
          ref="frame"
          :srcdoc="iframe.src"
          width="100%"
          height="600px"
          @load="load"
        />
      </div>
      <div v-if="payment_modal_tab==='error'">
        <div class="row justify-content-end mb-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            size="sm"
            type="submit"
            @click="closePaymentModal"
          >
            X
          </b-button>
        </div>
        <b-alert variant="danger" show>
          <h4 class="alert-heading">
            {{ $t('creditcardpay.payment_failed') }}
          </h4>
          <h4 class="alert-body">
            {{ payment_error_message }}
          </h4>
        </b-alert>
      </div>
      <div v-if="payment_modal_tab==='success'">
        <b-alert
          variant="success"
          show
        >
          <h4 class="alert-heading">
            {{ $t('creditcardpay.verification_successful') }}
          </h4>
          <h4 class="alert-body">
            {{ $t('creditcardpay.please_wait_until_payment_is_received') }}
          </h4>
        </b-alert>
      </div>
    </b-modal>
    <div class="py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <b-img
              :src="appLogoImage"
              style="max-height: 50px"
              alt="logo"
            />
          </div>
          <div>
            <div class="list-unstyled d-inline-flex">
              <locale />
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="ml-1"
                size="sm"
                variant="danger"
                @click="$router.push({ name: 'auth-logout'})"
              ><feather-icon
                 icon="LogOutIcon"
                 class="mr-50"
               />
                <span class="align-middle">{{ $t('core.btn_logout') }}</span>
              </b-button>
            </div>
          </div>
        </div>
        <hr>
        <div class="text-left">
          <small>{{ $t('creditcardpay.step') }} <b>3/3</b></small>
        </div>

        <h2>
          {{ $t('creditcardpay.setup_paymnet') }} 💳
        </h2>

        <!-- form -->
        <validation-observer ref="paymentForm" #default="{invalid}">
          <b-form @submit.prevent="validationForm" >
            <div class="mt-2">

              <!-- country and tax -->
              <b-row class="justify-content-center">
                <b-col md="4">
                  <b-form-group>
                    <label for="country"><span class="text-danger text-bold">*</span> {{ $t('creditcardpay.country') }}</label>
                    <b-form-input
                      id="country"
                      v-model="country"
                      disabled
                      name="country"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label for="tax_no"><span class="text-danger text-bold">*</span> {{ $t('creditcardpay.tax_no') }}</label>
                    <validation-provider
                      #default="{ errors }"
                      name="tax_no"
                      rules="required"
                    >
                      <b-form-input
                        id="tax_no"
                        v-model="tax_no"
                        :state="errors.length > 0 ? false: tax_no != null ? true : null"
                        name="tax_no"
                        :placeholder="$t('creditcardpay.tax_no')"
                      />
                      <small
                        v-if="errors[0]"
                        class="text-danger"
                      >{{ $t('core.required') }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr class="w-75">
              <!-- country and tax -->


              <!-- no show if amount 0 -->
              <div v-if="discount_after_price!=0">
                <!-- cardno -->
                <b-row class="justify-content-center">
                  <b-col md="8">
                    <b-form-group
                    >
                      <label for="card_no"><span class="text-danger text-bold">*</span> {{ $t('creditcardpay.card_no') }}</label>
                      <validation-provider
                          #default="{ errors }"
                          name="card_no"
                          rules="required"
                      >
                        <cleave
                            id="card_no"
                            v-model="card_no"
                            class="form-control"
                            :raw="false"
                            :options="options.creditCard"
                            :placeholder="$t('creditcardpay.card_no')"
                            :state="errors.length > 0 ? false: card_no != null ? true : null"
                        />
                        <small
                            v-if="errors[0]"
                            class="text-danger"
                        >{{ $t('core.required') }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- cardno -->

                <!-- exp_date and cvv -->
                <b-row class="justify-content-center">
                  <b-col md="4">
                    <b-form-group>
                      <label for="exp_date"><span class="text-danger text-bold">*</span> {{ $t('creditcardpay.exp_date') }}</label>
                      <validation-provider
                          #default="{ errors }"
                          name="exp_date"
                          rules="required"
                      >
                        <cleave
                            id="exp_date"
                            v-model="exp_date"
                            class="form-control"
                            :state="errors.length > 0 ? false: exp_date != null ? true : null"
                            :raw="false"
                            :options="options.exp_date"
                            :placeholder="$t('creditcardpay.exp_date_placeholder')"
                        />
                        <small
                            v-if="errors[0]"
                            class="text-danger"
                        >{{ $t('core.required') }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                    >
                      <label for="cvv"><span class="text-danger text-bold">*</span>CVV:</label>
                      <validation-provider
                          #default="{ errors }"
                          name="cvv"
                          rules="required"
                      >
                        <cleave
                            id="cvv"
                            v-model="cvv"
                            class="form-control"
                            :state="errors.length > 0 ? false: cvv != null ? true : null"
                            :raw="false"
                            :options="options.cvv"
                            placeholder="CVV"
                        />
                        <small
                            v-if="errors[0]"
                            class="text-danger"
                        >{{ $t('core.required') }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- exp_date and cvv -->

                <!-- full_name -->
                <b-row class="justify-content-center">
                  <b-col md="8">
                    <b-form-group
                    >
                      <label for="card_holder"><span class="text-danger text-bold">*</span> {{ $t('creditcardpay.card_holder') }}</label>
                      <validation-provider
                          #default="{ errors }"
                          name="card_holder"
                          rules="required"
                      >
                        <b-form-input
                            id="card_holder"
                            v-model="card_holder"
                            :state="errors.length > 0 ? false: card_holder != null ? true : null"
                            name="card_holder"
                            :placeholder="$t('creditcardpay.card_holder')"
                        />
                        <small
                            v-if="errors[0]"
                            class="text-danger"
                        >{{ $t('core.required') }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- full_name -->
              </div>
              <!-- no show if amount 0 -->

              <!-- plan_preview -->
              <b-row class="justify-content-center">
                <b-col
                  class="border rounded bg-tumblr"
                  md="8"
                >
                  <b-row class="justify-content-between">
                    <b-col>
                      <!-- Kupon girilen gösterilen ekran-->
                      <div v-if="discount_error_status==false">
                        <h5
                          style="text-decoration: line-through;"
                          class="text-white text-nowrap mt-1"
                        >
                          <div v-show="planData.plan.currency.is_before">
                            {{ $t('creditcardpay.amount_paid') }} {{ price_formetter(plan_price) }}/ <span v-if="planData.planPeriod=='annually'">{{ $t('creditcardpay.yearly') }}</span> <span v-if="planData.planPeriod=='monthly'">{{ $t('creditcardpay.monthly') }}</span>
                          </div>
                          <div v-show="!planData.plan.currency.is_before">
                            {{ $t('creditcardpay.amount_paid') }} {{ price_formetter(plan_price) }} / <span v-if="planData.planPeriod=='annually'">{{ $t('creditcardpay.yearly') }}</span> <span v-if="planData.planPeriod=='monthly'">{{ $t('creditcardpay.monthly') }}</span>
                          </div>
                        </h5>
                        <h6 class="text-white">
                          {{ $t('creditcardpay.selected_plan') }} <b>{{ planData.plan.title }}</b>
                        </h6>
                        <hr>
                        <h5 class="text-white text-nowrap mt-1">
                          <div v-show="planData.plan.currency.is_before">
                            {{ $t('creditcardpay.amount_paid') }} {{ price_formetter(discount_after_price) }}
                          </div>
                          <div v-show="!planData.plan.currency.is_before">
                            {{ $t('creditcardpay.amount_paid') }} {{ price_formetter(discount_after_price) }}
                          </div>
                        </h5>
                      </div>
                      <!-- Kupon girilen gösterilen ekran-->

                      <!-- Kupon girilmeden gösterilen ekran-->
                      <div v-if="discount_error_status!==false">
                        <h5 class="text-white text-nowrap mt-1">
                          <div v-show="planData.plan.currency.is_before">
                            {{ $t('creditcardpay.amount_paid') }} {{ price_formetter(plan_price) }} / <span v-if="planData.planPeriod=='annually'">{{ $t('creditcardpay.yearly') }}</span> <span v-if="planData.planPeriod=='monthly'">{{ $t('creditcardpay.monthly') }}</span>
                          </div>
                          <div v-show="!planData.plan.currency.is_before">
                            {{ $t('creditcardpay.amount_paid') }} {{ price_formetter(plan_price) }} / <span v-if="planData.planPeriod=='annually'">{{ $t('creditcardpay.yearly') }}</span> <span v-if="planData.planPeriod=='monthly'">{{ $t('creditcardpay.monthly') }}</span>
                          </div>
                        </h5>
                        <h6 class="text-white">
                          {{ $t('creditcardpay.selected_plan') }} <b>{{ planData.plan.title }}</b>
                        </h6>
                      </div>
                      <!-- Kupon girilmeden gösterilen ekran-->
                    </b-col>
                    <b-col>
                      <b-link :to="{name:'auth-register-planselect'}">
                        <h5 class="text-right text-white mt-2">
                          {{ $t('creditcardpay.change') }}
                        </h5>
                      </b-link>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <!-- plan_preview -->

              <!-- discount -->
              <b-row class="mt-1  justify-content-center">
                <b-col md="8">
                  <b-form-group
                    :label="$t('creditcardpay.discount_code')"
                    label-for="cardno"
                  >
                    <b-input-group>
                      <b-form-input
                        v-model="discount_code"
                        :disabled="discount_error_status==false"
                        :placeholder="$t('creditcardpay.discount_code')"
                      />
                      <b-input-group-append>
                        <b-button
                          v-if="discount_error_status!==false"
                          variant="outline-primary"
                          @click="checkDiscount()"
                        >
                          <feather-icon
                            icon="PercentIcon"
                            class="mr-25"
                          />{{ $t('creditcardpay.apply_discount') }}
                        </b-button>
                        <b-button
                          v-if="discount_error_status==false"
                          variant="outline-danger"
                          @click="removeDiscount()"
                        >
                          <feather-icon
                            icon="PercentIcon"
                            class="mr-25"
                          />{{ $t('creditcardpay.take_back_discount') }}
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <b-alert
                        v-if="discount_error_status"
                        variant="danger"
                        show
                        class="mt-50"
                    >
                      <h4 class="alert-heading">
                        {{ $t('creditcardpay.discount_not_found') }}
                      </h4>
                    </b-alert>
                  </b-form-group>
                </b-col>

              </b-row>

              <!-- discount -->

              <!-- lisance -->
              <b-row class="  justify-content-center">
                <b-col md="8">
                  <p class="mt-1">
                    {{ $t('creditcardpay.warning_1') }}
                  </p>
                  <p>
                    {{ $t('creditcardpay.warning_2') }}
                  </p>
                  <p class="mt-1">{{ $t('creditcardpay.warning_3') }}</p>
                  <p><b>geribildir.io</b>, {{ $t('creditcardpay.warning_4') }}</p>
                  <p>{{ $t('creditcardpay.warning_4') }}</p>
                </b-col>
              </b-row>
              <!-- lisance -->

              <!-- checkbox -->
              <b-row class="  justify-content-center mt-1">
                <b-col md="8">
                  <validation-provider
                      #default="{ errors }"
                      name="checkbox"
                      rules="required"
                  >
                    <b-form-checkbox
                        id="register-privacy-policy"
                        v-model="chekcbox_status"
                        :value="true"
                        :unchecked-value="null"
                        switch
                        :state="chekcbox_status != null ? true : null"
                        name="checkbox-1"
                    >
                      {{ $t('creditcardpay.accept') }}
                    </b-form-checkbox>
                    <small
                        v-if="chekcbox_status == false"
                        class="text-danger"
                    >{{ $t('core.required') }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <!-- checkbox -->

              <!-- accept_btn -->
              <b-row class="  justify-content-center mt-1 mb-2">
                <b-col md="8">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :disabled="invalid"
                    block
                    variant="primary"
                    type="submit"
                  >
                    {{ $t('creditcardpay.start_subscription') }}
                  </b-button>
                </b-col>
              </b-row>
              <!-- accept_btn -->
            </div>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol, BBadge, BImg, BAlert,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'
import Ripple from 'vue-ripple-directive'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'
import AppCollapse from '@core/components/app-collapse/AppCollapse'
import { $themeConfig } from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt'
import plan from '@/views/custom_app/admin/plan/Plan.vue'
import Cleave from "vue-cleave-component";

export default {
  components: {
    VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
    Locale,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BBadge,
    BAlert,
    BImg,
    AppCollapseItem,
    AppCollapse,
    Cleave,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        cardNumber: null,
        date: null,
        phone: null,
      },
      options: {
        creditCard: {
          delimiter: '-',
          blocks: [4, 4, 4, 4],
        },
        cvv: {
          blocks: [3],
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        exp_date: {
          date: true,
          delimiter: '/',
          datePattern: ['m', 'y'],
          blocks: [2, 2],
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
        time: {
          time: true,
          timePattern: ['h', 'm', 's'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true,
        },
        delimiter: {
          delimiter: '·',
          blocks: [3, 3, 3],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: '+63',
          blocks: [3, 3, 3, 4],
          uppercase: true,
        },
      },
      temp_order_no: null,
      payment_modal_tab: 'iframe',
      payment_error_message: null,
      country: null,
      discount_code: null,
      discount_error_status: null,
      discount_error_message: null,
      discount_after_price: null,
      plan_price: 0,
      tax_no: null,
      card_no: null,
      exp_date: null,
      cvv: null,
      card_holder: null,

      // validation rules
      required,
      email,
      chekcbox_status: null,
      monthlyPlanShow: true,
      planData: {},
      iframe: {
        src: null,
        loaded: false,
      },
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  computed: {
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage)
  },
  created() {
    this.planData = JSON.parse(localStorage.getItem('planData'))
    if (this.planData.planPeriod === 'monthly') {
      this.plan_price = this.planData.plan.monthly_price
    } else if (this.planData.planPeriod === 'annually') {
      this.plan_price = this.planData.plan.annual_price
    }
    this.getCountry()
  },
  methods: {
    price_formetter(price) {
      const currency = this.planData.plan.currency.currency_name
      let formatter = null
      if (currency == 'TL') {
        formatter = new Intl.NumberFormat('tr-TR', {style: 'currency', currency: 'TRY',})
      } else if (currency == 'USD') {
        formatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD',})
      } else if (currency == 'EUR') {
        formatter = new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR',})
      }
      return formatter.format(price)
    },
    receiveMessage(event) {
      if (event.data.func === 'payment_log') {
        if (event.data.message.payment_status === 'True') {
          this.payment_modal_tab = 'success'
          this.PaymentCheck()
        } else {
          this.payment_modal_tab = 'error'
          this.payment_error_message = event.data.message.message
        }
      }
    },
    getCountry() {
      useJwt.clientregistercheckcountry({})
        .then(response => {
          this.country = `${response.data.emoji} ${response.data.native}`
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    removeDiscount() {
      this.discount_code = null
      this.discount_after_price = null
      this.discount_error_status = null
      this.discount_error_message = null
    },
    checkDiscount() {
      this.discount_error_status = null
      this.discount_error_message = null
      useJwt.checkDiscount({
        discount_code: this.discount_code,
        plan_id: this.planData.plan.id,
        plan_period: this.planData.planPeriod,

      })
        .then(response => {
          if (response.data.status === false) {
            this.discount_error_status = true
            this.discount_error_message = response.data.error_message
          } else {
            this.discount_error_status = false
            if (response.data.type === 'percentage') {
              this.discount_after_price = this.plan_price - (this.plan_price * response.data.discount_value / 100)
            } else if (response.data.type === 'fixed') {
              let ss = this.plan_price - response.data.discount_value
              if (ss < 0) {
                ss = 0
              }
              this.discount_after_price = ss
            }
          }
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    load() {this.iframe.loaded = true},
    PaymentCheck() {
      const payload = {
        order_no: this.temp_order_no,
      }
      useJwt.paymentcheck(payload)
        .then(response => {
          if (response.data.order_status === 'processing') {
            setTimeout(this.PaymentCheck, 3000) // 3 saniye sonra tekrar çalışır
          } else if (response.data.order_status === 'completed') {
            localStorage.setItem('userData', JSON.stringify(response.data.userData))
            this.$ability.update(response.data.userData.ability)
            let routerPath = { name: 'dashboard' }
            this.$router.replace(routerPath)
          } else if (response.data.order_status === 'canceled') {
            this.payment_modal_tab = 'error'
            this.payment_error_message = `${response.data.failed_reason_code} - ${response.data.failed_reason_msg}`
          }
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    validationForm() {
      this.$refs.paymentForm.validate().then(success => {
        if (success) {
          this.temp_order_no = null
          let payload = {}
          if (this.discount_after_price==0){
            payload = {
              plan_period: this.planData.planPeriod,
              plan_tag: this.planData.plan.plan_tag,
              tax_no: this.tax_no,
              card_no: "1234123412341234",
              exp_date: "12/40",
              cvv: "000",
              card_holder: "free free",
            }
          }else {
            payload = {
              plan_period: this.planData.planPeriod,
              plan_tag: this.planData.plan.plan_tag,
              tax_no: this.tax_no,
              card_no: this.card_no.replaceAll("-",""),
              exp_date: this.exp_date,
              cvv: this.cvv,
              card_holder: this.card_holder,
            }
          }

          if (this.discount_code !== null & this.discount_error_status == false & this.discount_code !== '') {
            payload.discount_code = this.discount_code
          }
          useJwt.clientregistercreditcartpay(payload)
            .then(response => {
              if (response.data.status === true) {
                if (response.data.action == 'login') {
                  localStorage.setItem('userData', JSON.stringify(response.data.userData))
                  this.$ability.update(response.data.userData.ability)
                  let routerPath = { name: 'dashboard' }
                  this.$router.replace(routerPath)
                } else {
                  this.$bvModal.show('modal-1')
                  // Param pos//
                  this.iframe.src = response.data.threeDSHtmlContent
                  this.temp_order_no = response.data.order_no
                  // Param pos//
                  // iyzicp pos//
                  // const threeDSHtmlContent = atob(response.data.threeDSHtmlContent)
                  // this.iframe.src = `data:text/html;charset=utf-8,${encodeURI(threeDSHtmlContent)}`
                  // iyzicp pos//
                }
              } else {
                this.$bvModal.hide('modal-1')
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('core.error'),
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              console.log(error.response)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('core.error'),
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
                },
              })

            })
        }
      })
    },
    closePaymentModal() {
      this.$bvModal.hide('modal-1')
      this.iframe = { src: null, loaded: false }
      this.payment_modal_tab = 'iframe'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
